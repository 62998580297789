import React, { useState , useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner"; // Spinner Component
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-blue/theme.css"; // Theme
import "primereact/resources/primereact.min.css"; // PrimeReact styles
import "./App.css";
import { API_URL } from "../../config";

// const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const MedicalReportPage = () => {
  const [currentState, setCurrentState] = useState("initial"); // States: initial, listening, loading, table
  const [apiResponseData, setApiResponseData ] = useState(null);
  const [selectedReportType, setSelectedReportType] = useState(null);
  const [showVideo, setShowVideo] = useState(false); 
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const isExit = useRef(false);

  const toggleVideo = () => setShowVideo((prev) => !prev);
  
  const reportTypes = [
    { label: "Consultation- General", value: "Consultation- General" },
  ];
  React.useEffect(() => {
    console.log("apiResponseData updated:", apiResponseData);
  }, [apiResponseData]);

  const stopMicrophone = () => {
    if (mediaRecorderRef.current?.stream) {
      mediaRecorderRef.current.stream.getTracks().forEach((track) => {
        track.stop();
      });
      console.log("Microphone stopped.");
    }
  };

  const handleStartListening = async () => {
    console.log('handleStartListening')
    setCurrentState("listening");
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];
      mediaRecorderRef.current.ondataavailable = (event) => {
        console.log('recorder ondataavailable', event.data);
        audioChunksRef.current.push(event.data);
      };
      mediaRecorderRef.current.onstop = async () => {
        stopMicrophone();
        if(isExit.current){
          isExit.current = false;
          setCurrentState("initial");
          setApiResponseData(null);
          return;
        }
        console.log(' recorder on stop wef2', isExit);
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        const audioFile = new File([audioBlob], 'audio.wav', { type: 'audio/wav' });
        const formData = new FormData();
        formData.append('audio', audioFile);
        formData.append('data', JSON.stringify(apiResponseData));
        
        try {
          console.log('loading state setting');
          setCurrentState("loading");
          const response = await fetch(`${API_URL}/voice/process-audio`, {
            method: 'POST',
            body: formData,
          });
          if (response.ok) {
            const jsonResponse = await response.json();
            setApiResponseData({ ...jsonResponse });
            setCurrentState("table");
          }
        } catch (error){
          console.log('err in uploading audio file', error);
        }
      };
      mediaRecorderRef.current.start();
    } catch (err){
      console.log('err in listening', err);
    }
  };

  const handleExitListening = async() => {
    isExit.current = true;
    if(mediaRecorderRef.current)mediaRecorderRef.current.stop()
  };

  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleGenerateReport = async () => {
    if(mediaRecorderRef.current)mediaRecorderRef.current.stop();
  };

  const handleRetry = () => {
    audioChunksRef.current = [];
    handleStartListening();
  };

  const closeTable = () => {
    setCurrentState("initial");
    setApiResponseData(null);
  };

  const handleGeneratePDF = async () => {
    if (!apiResponseData) {
      console.log("No data to generate the PDF.");
      return;
    }
  
    try {
      // Make a POST request to fetch the PDF
      const response = await fetch(`${API_URL}/voice/v2/report-print`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(apiResponseData), // Send the report data
      });
  
      if (response.ok) {
        // Get the binary data (PDF file)
        const pdfBlob = await response.blob();
  
        // Create a temporary URL for the PDF file
        const pdfUrl = URL.createObjectURL(pdfBlob);
  
        // Create a temporary anchor element to trigger the download
        const anchor = document.createElement("a");
        anchor.href = pdfUrl;
        anchor.download = "Generated_Report.pdf"; // Set the file name
        document.body.appendChild(anchor); // Append the anchor to the DOM
        anchor.click(); // Trigger the download
        document.body.removeChild(anchor); // Clean up the DOM
  
        // Revoke the temporary URL to free memory
        URL.revokeObjectURL(pdfUrl);
      } else {
        console.error("Failed to generate PDF:", response.statusText);
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handleCellChange = (key, newValue) => {
    setApiResponseData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  }
  
  
  return (
    <div className="container">
      <header className="header">
        <h1>Jeeva Voice EHR</h1>
        <h2>Generate Voice Report</h2>
      </header>

      <div className="form-row">
        <div>
          <Dropdown
            id="reportType"
            value={selectedReportType}
            options={reportTypes}
            onChange={(e) => setSelectedReportType(e.value)}
            placeholder="Select Report Type"
            className="p-dropdown"
          />
        </div>
        {(currentState !== "loading" && currentState !== "listening") && (
          <Button
            label={currentState === "table" ? "Click to Modify" : "Click to Start"}
            icon="pi pi-microphone"
            className="p-button-primary"
            onClick={
              currentState === "table" ? handleRetry : handleStartListening
            }
          />
        )}
      </div>

      {(currentState === "initial"  && !apiResponseData) && (
        <div className="initial-state">
          {!showVideo ? (
            <>
             <p style={{ marginBottom: "2px", fontSize: "1.2rem", color: "#4a5568" }}>Click Image To See Demo</p>
              <img
                className="display-image"
                src="https://healthai-vault.s3.ap-south-1.amazonaws.com/A_minimalistic_illustration_featuring_a_microphone.jpg"
                alt="Microphone Illustration"
                onClick={toggleVideo}
                style={{ cursor: "pointer",marginTop: "0"  }}
              />
            </>
          ) : (
            <div style={{ position: "relative" }}>
              <Button
                icon="pi pi-times"
                className="p-button-rounded p-button-text close-button"
                onClick={toggleVideo}
                aria-label="Close"
              />
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/-H7B10W2tJ4?si=a989TBAFbKlcbHYs"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      )}

      {currentState === "listening" && (
        <div className="listening-wave-container">
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-text close-button"
            onClick={handleExitListening}
            aria-label="Close"
          />
          <div className="listening-wave">
            <div className="wave-bar"></div>
            <div className="wave-bar"></div>
            <div className="wave-bar"></div>
            <div className="wave-bar"></div>
            <div className="wave-bar"></div>
          </div>
          <div className="listening-icon">
            <i className="pi pi-microphone"></i>
            <span className="listening-text">Listening...</span>
            <i className="pi pi-volume-up"></i>
          </div>
          <div className="button-container">
            <Button className="button primary" onClick={handleGenerateReport}>
              {(currentState === "table" || apiResponseData) ? "Update Report" : "Generate Report"}
            </Button>
          </div>
        </div>
      )}

      {currentState === "loading" && (
        <div className="loading-state">
          <ProgressSpinner />
          <p className="status-text">Processing Voice, Please wait.....</p>
        </div>
      )}

      {(currentState === "table" || apiResponseData ) && (
        <div className="table-state">
          <table className="response-table">
            <tbody>
              {Object.entries(apiResponseData || {}).map(([key, value], index) => (
                <tr key={index}>
                  <td>{key}</td>
                  <td>
                    <textarea 
                      className="editable-cell"
                      value={value}
                      rows={2}
                      onChange={(e) => handleCellChange(key, e.target.value)}
                    ></textarea>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="close-table-button">
            <Button
              label="Close"
              className="p-button-secondary"
              onClick={closeTable}
            />
          </div>
        </div>
      )}

      <div className="footer-buttons">
        <Button className="button contact-button" onClick={() => window.location.href = "https://www.jeeva-ai.com/contact-us"}>Contact for Integration</Button>
        {currentState === "table" && (
          <Button className="button secondary" onClick={handleGeneratePDF}>Download Report</Button>
        )}
      </div>
    </div>
  );
};

export default MedicalReportPage;

