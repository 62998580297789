import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import 'primeicons/primeicons.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeflex/primeflex.css";
import ReportGenerator from "./Pages/v2/ReportGenerator";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element= { <ReportGenerator  /> } />
      </Routes>
    </Router>
  );
};

export default App;
